define("@ember/-internals/utility-types/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Opaque = void 0;

  class Opaque {}

  _exports.Opaque = Opaque;
});